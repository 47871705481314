import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeeSurveysAiSummaryShowResponseType } from "~/domains";

type RequestDataType = {
  employeeSurveyId: string;
};

const request = async ({
  employeeSurveyId,
}: RequestDataType): Promise<ApiManagersEmployeeSurveysAiSummaryShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersEmployeeSurveysAiSummaryShowResponseType>(
      `/api/managers/employee_surveys/${employeeSurveyId}/ai_summary`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeSurveyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeeSurveysAiSummaryShow = ({
  employeeSurveyId,
  config = {},
}: PropsType): UseQueryResult<ApiManagersEmployeeSurveysAiSummaryShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersEmployeeSurveysAiSummaryShow", employeeSurveyId],
    queryFn: () => request({ employeeSurveyId }),
  });
};
